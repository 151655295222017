<template>
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <Navigation></Navigation>
    <div class="theme-container">
      <PageTitle :title="pageTitle" :imgSrc="headerImage"></PageTitle>
      <div class="letter-container">
        <div class="container">
        <div class="content-wrapper">
          <div class="">
            <h2>{{ letter.greeting }}</h2>
            <div v-for="bodytext in letter.body">
              <p v-html="bodytext"></p>
            </div>
            <div v-for="signature in letter.signatures" class="signature-container">
              <Signature
                :name="signature.name"
                :picture='signature.picture'
                :signature="signature.signaturePicture"
                :title="signature.position"
                style = "margin-left: 5px"
              >
              </Signature>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="card-container d-flex justify-content-center">
      <div class="container">
          <Card
            :title="overview.title"
            :optionalContent="overview.body"
            :imgSrc="overview.picture"
          >
          </Card>
        </div>
      </div>
      <div class="quotes-container d-flex justify-content-center">
        <div class="container">
          <h1>{{ quotes.title }}</h1>
          <div v-for="item in quotes.quote" class="quotes-wrapper">
            <QuoteCard
              :title="item.title"
              :imgSrc="item.picture"
              :content="item.body[0]"
              :name="item.name"
              :titleSlalom="item.position"
            >
            </QuoteCard>
          </div>
        </div>
      </div>
    </div>
    <BottomNavigator :id="summaryPageId" />
  </Loading>
</template>

<script>
import BottomNavigator from '@/components/BottomNavigator.vue';
import Card from '@/components/Card.vue';
import Loading from '@/components/Loading.vue';
import Navigation from '@/components/Navigation.vue';
import PageTitle from '@/components/PageTitle.vue';
import QuoteCard from '@/components/QuoteCard.vue';
import Signature from '@/components/Signature.vue';
import Alert from '@/components/Alert.vue';
import api from '@/services/api.js';

export default {
	data: () => ({
		isLoading: false,
		isError: false,
		errorMessage: '',
		pageTitle: '',
		headerImage: '',
		letter: {
			greeting: '',
			body: [],
			signatures: [],
		},
		overview: {
			title: '',
			body: [],
			picture: '',
		},
		quotes: {
			title: '',
			quote: []
		}
	}),
	computed: {
		summaryPageId() {
			const summaryPage = this.$store.state.pages.summary;
			if(summaryPage){
				return summaryPage.id;
			}
			return null;
		},
	},

	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getSummaryPage(this.summaryPageId);
				this.headerImage = data.pageIcon;
				this.pageTitle = data.pageTitle;
				this.letter = {...data.letter}
				this.overview = {...data.overview}
				this.quotes = {...data.quotes }
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		},
	},
	watch:{
		summaryPageId(newValue, oldValue) {
			if (newValue) {
				this.getData()
			}
		}
	}, 
	async beforeRouteUpdate(to, from, next) {
		//await this.getData();
		next();
	},

	async created() {
		if(this.summaryPageId){
			await this.getData();
		}
	},

	components: {
		BottomNavigator,
		PageTitle,
		Loading,
		Navigation,
		Card,
		QuoteCard,
		Signature,
		Alert,
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  @include relative;
  @include margin(0 0 10 0);
  :deep(.page-title) {
    .title-container {
      background-color: $primary-color-1;
    }
  }
}

.card-container {
  @include padding(18 10 18 10);
  @include responsive(phone) {
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  :deep(.img-container) {
  }
}

.signature {
  @include margin-left(5);
  @include padding-left(5);
}

.frame-title {
  color: secondary-color-9;
  font-size: 2em;
  @include padding(0 0 0 2);
}

.letter-container {
  background-color: $secondary-color-4;
  @include padding(8);
  @include responsive(phone) {
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.quotes-container {
  @include padding(8 16 8 16);
  @include responsive(phone) {
    @include margin(0 1 0 1);
    @include padding(4 4 4 4);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  h1 {
    color: $primary-color-1;
    font-size: 48px;
    line-height: 120%;
  }
  .quotes-wrapper {
    @include padding(0 10 0 10);
    @include responsive(phone) {
      @include padding(0);
    }
    @include responsive(tablet) {
      @include padding(0);
    }
  }
}

.quote-container p {
  border-left: 5px solid #edf2f4;
  @include padding-left(5);
}

h2 {
  @include margin-bottom(1);
  color: $secondary-color-7;
  border-bottom: none;
  font-size: 28px;
}

p {
  font-weight: normal;
  font-size: 18px;
  color: $secondary-color-7;
}

.content-wrapper {
  @include padding(8);
  background-color: (white);
  border-bottom-right-radius: 4em;
  @include responsive(phone) {
    @include margin(0 1 0 1);
    @include padding(4 4 4 4);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.div-wrapper {
  @include padding(8);
}

.img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  @include margin(2);
  @include border-radius(2);
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
}

.signature-container {
  display: block;
}
</style>